export const environment = {
  production: true,
  redirectUri: 'https://dev.yoda.ienergy.halliburton.com',
  clientId: 'b19241ac-6879-4420-9202-f68270aeaa85',
  authority:
    'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
  scopes: 'b19241ac-6879-4420-9202-f68270aeaa85/.default',
  vapidPublicKey:
    'BPNLY7kjoP-nY5hAXL9SF5XUVZCNJKq_VCTd8kdP6fBhT2uF4s-sz-p46OVRw3WAallGc_P-TikfiD2Uy6-Idhs',
  api: 'https://dev-api.yoda.ienergy.halliburton.com',
};
